// components/Notes.js
import React, { useEffect, useState, useContext } from 'react';
import api from '../api';
import LoadingButton from '../UI/LoadingButton';
import TagManager from './TagManager';
import AddTags from './AddTags';
import './Notes.css';
import { generateTagColor } from '../../utils/colorUtils';
import { normalizeText } from '../../utils/stringUtils';
import { AuthContext } from '../Login/AuthContext';

const Notes = ({ onOpenNote, onOpenTodolist, onOpenPricing }) => {
    const [notes, setNotes] = useState([]);
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
    const [isAddTagsOpen, setIsAddTagsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { subscription } = useContext(AuthContext);

    const fetchNotes = async () => {
        try {
            const response = await api.get('/fetch_notes');
            if (response.data.result === "OK") {
                const combined = [...response.data.notes, ...response.data.todolists];
                combined.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));
                setNotes(combined);
            } else {
                console.error("Erreur : ", response.data.message);
            }
        } catch (error) {
            console.error("Erreur de récupération des notes :", error);
        }
    };

    useEffect(() => {
        fetchNotes();
    }, []);

    const truncateText = (text, maxWords) => {
        const words = text.split(' ');
        return words.length > maxWords
            ? `${words.slice(0, maxWords).join(' ')} [...]`
            : text;
    };

    const getDescription = (item) => {
        if (item.type === 'note') {
            return truncateText(item.text, 50);
        } else if (item.type === 'todolist') {
            return item.tasks.map((task) => `- ${task.text}`).join('<br>');
        }
        return '';
    };

    const handleDeleteSelected = async () => {
        try {
            setIsLoading(true);
            for (const item of notes.filter(note => selectedNotes.includes(note.id))) {
                if (item.type === 'note') {
                    await api.delete(`/notes/${item.id}`);
                } else if (item.type === 'todolist') {
                    await api.delete(`/todolists/${item.id}`);
                }
            }
            setNotes(notes.filter(note => !selectedNotes.includes(note.id)));
            setSelectedNotes([]);
            setIsSelectionMode(false);
        } catch (error) {
            console.error("Erreur lors de la suppression des éléments :", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenItem = (item) => {
        if (isSelectionMode) {
            handleSelectNote(new Event('click'), item.id);
            return;
        }
        if (item.type === 'note') {
            onOpenNote(item.id);
        } else if (item.type === 'todolist') {
            onOpenTodolist(item.id);
        }
    };

    const handleSelectNote = (e, noteId) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        setSelectedNotes(prev =>
            prev.includes(noteId)
                ? prev.filter(id => id !== noteId)
                : [...prev, noteId]
        );
    };

    const toggleSelectionMode = () => {
        setIsSelectionMode(!isSelectionMode);
        if (isSelectionMode) {
            setSelectedNotes([]);
        }
    };

    const filterNotes = (notes) => {
        if (!searchQuery.trim()) return notes;

        const normalizedQuery = normalizeText(searchQuery);

        return notes.filter(item => {
            const normalizedName = normalizeText(item.name);
            const normalizedContent = item.type === 'note' 
                ? normalizeText(item.text)
                : normalizeText(item.tasks.map(task => task.text).join(' '));
            const normalizedTags = item.tags 
                ? item.tags.map(tag => normalizeText(tag.name)).join(' ')
                : '';
            const normalizedType = normalizeText(item.type === 'note' ? 'note' : 'todolist');

            return normalizedName.includes(normalizedQuery) ||
                   normalizedContent.includes(normalizedQuery) ||
                   normalizedTags.includes(normalizedQuery) ||
                   normalizedType.includes(normalizedQuery);
        });
    };

    return (
        <div className="panel notes-section">
            <div className="notes-header">
                <h1>Notes</h1>
                <div className="search-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search in notes..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="material-symbols-outlined search-icon">search</span>
                </div>
                <div className="notes-actions-container">
                    <div className="toggle-container">
                        
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={isSelectionMode}
                                onChange={toggleSelectionMode}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                        <span className="toggle-label">Select notes</span>
                        
                    </div>

                    <div className="notes-actions-buttons">
                    {/* Manager tags toujours visible */}
                    {/* Version desktop */}
                    <LoadingButton
                        className="button-plain manager-button-desktop"
                        onClick={() => subscription?.active ? setIsTagManagerOpen(true) : onOpenPricing()}
                    >
                        <span className="material-symbols-outlined icon">settings</span>
                        Manage tags
                    </LoadingButton>

                    {/* Version mobile */}
                    <LoadingButton
                        className="button-plain manager-button-mobile"
                        onClick={() => subscription?.active ? setIsTagManagerOpen(true) : onOpenPricing()}
                    >
                        <span className="material-symbols-outlined icon">settings</span>
                    </LoadingButton>

                    {isSelectionMode && (
                        <>
                            {/* Version desktop */}
                            <LoadingButton
                                className="button-plain delete-button-desktop"
                                onClick={handleDeleteSelected}
                                disabled={selectedNotes.length === 0}
                                isLoading={isLoading}
                            >
                                <span className="material-symbols-outlined icon">delete</span>
                                Delete ({selectedNotes.length})
                            </LoadingButton>

                            {/* Version mobile */}
                            <LoadingButton
                                className="button-plain delete-button-mobile"
                                onClick={handleDeleteSelected}
                                disabled={selectedNotes.length === 0}
                                isLoading={isLoading}
                            >
                                <span className="material-symbols-outlined icon">delete</span>
                                ({selectedNotes.length})
                            </LoadingButton>

                            {/* Tags - Version desktop */}
                            <LoadingButton
                                className="button-plain tags-button-desktop"
                                onClick={() => setIsAddTagsOpen(true)}
                                disabled={selectedNotes.length === 0}
                            >
                                <span className="material-symbols-outlined icon">label</span>
                                Add tags
                            </LoadingButton>

                            {/* Tags - Version mobile */}
                            <LoadingButton
                                className="button-plain tags-button-mobile"
                                onClick={() => setIsAddTagsOpen(true)}
                                disabled={selectedNotes.length === 0}
                            >
                                <span className="material-symbols-outlined icon">label</span>
                            </LoadingButton>
                        </>
                    )}
                    </div>
                </div>
            </div>
            <div className="notes-container">
                {filterNotes(notes).map((item) => (
                    <div
                        className={`note-card ${isSelectionMode ? 'selection-mode' : ''}`}
                        key={item.id}
                        onClick={() => handleOpenItem(item)}
                    >
                        {isSelectionMode && (
                            <input
                                type="checkbox"
                                className="note-checkbox"
                                checked={selectedNotes.includes(item.id)}
                                onChange={(e) => handleSelectNote(e, item.id)}
                                onClick={(e) => e.stopPropagation()}
                            />
                        )}
                        <div className="note-content">
                            <h3 className="note-title">{item.name}</h3>
                            <p
                                className="note-description"
                                dangerouslySetInnerHTML={{ __html: getDescription(item) }}
                            ></p>
                        </div>
                        <div className="note-footer">
                            <span className="note-date">
                                {new Date(item.creation_date * 1000).toLocaleDateString()}
                            </span>
                            <div className="note-info">
                                <span className="note-category">
                                    <span className="material-symbols-outlined">
                                        {item.type === 'note' ? 'description' : 'list'}
                                    </span>{' '}
                                    {item.type === 'note' ? 'Note' : 'Todolist'}
                                </span>
                                {item.tags && item.tags.map(tag => (
                                    <span 
                                        key={tag.id} 
                                        className="tag"
                                        style={{ backgroundColor: generateTagColor(tag.name) }}
                                    >
                                        {tag.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {isTagManagerOpen && (
                <TagManager 
                    onClose={() => setIsTagManagerOpen(false)} 
                    onUpdate={fetchNotes} 
                />
            )}
            {isAddTagsOpen && (
                <AddTags 
                    onClose={() => {setIsAddTagsOpen(false);}}
                    selectedItems={notes.filter(note => selectedNotes.includes(note.id))}
                    onUpdate={fetchNotes}
                />
            )}
        </div>
    );
};

export default Notes;
